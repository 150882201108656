import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { ReactComponent as FilterAltOutlinedIcon } from '../../../static/icons/filter.svg';
import { Badge, Box, Card, Chip, Grid, IconButton, Popover, Stack, Theme, Typography } from '@mui/material';
import * as React from 'react';
import { getAgentEvents, getAgentPerformanceDigital, getWidgetConfig } from '../../../services/api-service';
import { WIDGET_REFRESH_INTERVAL } from '../../../utils/constants';
import FilterDrawer from '../../FilterDrawer/FilterDrawer';
import OZTable from '../../OZTable/OZTable';
import ThresholdDrawer from '../../ThresholdDrawer/ThresholdDrawer';
import WidgetDownload from '../../Widget-Download/Widget-Download';
import WidgetGraph from '../../Widget-Graph/Widget-Graph';
import '../AgentPerformanceDigital/AgentPerformanceDigital.css';
import { checkDataAccessFilters, determineFilterType, getTotalRowAndActualData, mapRowDataWithThreshold, secondsToMinSec } from '../../../utils/util';
import { AccessTime } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import useChartResize from '../../../hooks/useChartResize';
import { ReactComponent as RefreshOutlinedIcon } from '../../../static/icons/refresh.svg';
import { getLastFetchedDetail } from '../../../services/application-service';

const thresholdItemsDefault = [
	{
		label: '',
		key: 'general',
		fields: [
			{ label: 'Avg Hold Time', key: 'AverageHoldDuration', value: '' },
			{ label: 'Abandoned', key: 'AbandonedCalls', value: '' },
			{ label: 'AHT', key: 'AverageHandlingDuration', value: '' },
			{ label: 'ASA', key: 'AverageSpeedToAnswer', value: '' },
			{ label: 'ACW', key: 'WrapupDuration', value: '' },
			{ label: 'Pause Time', key: 'TotalPauseDuration', value: '' }
		]
	}
];

const useStyles: any = makeStyles((theme: Theme) => ({
	popoverContent: {
		minWidth: 350,
		fontSize: '12px',
		lineHeight: 2
	},
	dragdropItemsWrapper: {
		display: 'flex',
		height: '100%',
		width: '100%',
		margin: '0 1rem',
		alignItems: 'center'
	}
}));

let interval: any;

function AgentPerformanceDigital(props: any) {
	const { useState, useRef } = React;
	const classes = useStyles();
	const downloadContent = useRef<null | HTMLElement>(null);
	const [isDrawerOpen, setIsDrawerOpen] = useState<boolean | undefined>(false);
	const [isThresholdDrawerOpen, setIsThresholdDrawerOpen] = useState<boolean | undefined>(false);
	const [rowData, setRowData] = useState<Array<any>>([]);
	const [columnData, setColumnData] = useState<Array<any>>([]);
	const [responseLength, setresponseLength] = useState<Number>(0);
	const [thresholdItems, setThresholdItems] = useState<Array<any>>(thresholdItemsDefault);
	const [agentPerformanceFilter, setAgentPerformanceFilter] = useState<any>(props?.selectedFilters?.filters || []);
	const [filterType, setFilterType] = useState<any>(props?.selectedFilters?.filterSection?.id || (checkDataAccessFilters() ? 3 : 1));
	const [filterSection, setFilterSection] = useState<any>(props?.selectedFilters?.filterSection || {});
	const [selectedAgent, setSelectedAgent] = useState<any>(null);
	const [selectedAgentEventDetails, setSelectedAgentEventDetails] = useState<any>(null);
	const [eleRefPoint, setElRefPoint] = useState<any>(null);
	const [isEventsPopoverOpen, setIsEventsPopoverOpen] = useState<boolean>(false);
	const { chartContainerRef, chartDimensions, } = useChartResize();
	const [allowedFilters, setAllowedFilters] = useState<string[]>(['Campaign', 'Skill', 'Group']);
	const [lastFetchedTime, setLastFetchedTime] = useState<any>('00:00');
	const [savedThresholdList, setSavedThresholdList] = useState<any>({
		thresholdList: props?.selectedThresholds?.thresholdList?.length ? props?.selectedThresholds?.thresholdList : [],
		showSkillList: props?.selectedThresholds?.showSkillList || false
	});

	const clearFilter = () => {
		setAgentPerformanceFilter([]);
		props?.handleFilterChange?.([], filterSection, props?.widgetId);
	};

	const handleFilterDelete = (id: Number) => {
		const newFilter = agentPerformanceFilter.filter((item: any) => item.id !== id);
		setAgentPerformanceFilter(newFilter);
		props?.handleFilterChange?.(newFilter, filterSection, props?.widgetId);
	};

	const filterItems = agentPerformanceFilter.map((item: any, index: number) => (
		<Chip key={index} label={item.label} color="primary" variant="outlined" onDelete={() => handleFilterDelete(item.id)} deleteIcon={<CloseOutlinedIcon style={{ fontSize: '12px' }} />} />
	));

	const agentEvents = async (event: any, agent: any) => {
		setSelectedAgent(agent);
		let response = await getAgentEvents({ agentId: agent.AgentID });
		response = response.map((dataItem: any) => ({
			...dataItem,
			StartTime: dataItem.StartTime ? `${dataItem.StartTime.toString().split(' ')[0]}T${dataItem.StartTime.toString().split(' ')[1]}` : '',
			EndTime: dataItem.EndTime ? `${dataItem.EndTime.split(' ')[0]}T${dataItem.EndTime.toString().split(' ')[1]}` : ''
		}));
		setSelectedAgentEventDetails(response);
		setElRefPoint({ top: event.clientY, left: event.clientX });
		setIsEventsPopoverOpen(true);
	};
	const handleOnEventsPopoverClose = () => {
		setElRefPoint(null);
		setSelectedAgent(null);
		setIsEventsPopoverOpen(false);
	};

	const getColorByAgentEvent = (event: string) => {
		return event.toLowerCase() === 'calling'
			? 'yellow'
			: event.toLowerCase() === 'aux'
				? 'green'
				: event.toLowerCase() === 'release'
					? 'red'
					: event.toLowerCase() === 'idle'
						? 'orange'
						: event.toLowerCase() === 'incall'
							? 'lightgreen'
							: '';
	};
	const getTimeDifferenceInString = (higherDate: string, lowerDate: string) => {
		const seconds = (new Date(higherDate).getTime() - new Date(lowerDate).getTime()) / 1000;
		if (!isNaN(seconds)) {
			if (seconds > 60) {
				const minutes = seconds / 60;
				if (minutes > 60) {
					const hours = minutes / 60;
					return `${hours.toFixed(1)} Hrs`;
				} else {
					return `${Math.floor(minutes)} Mins`;
				}
			} else {
				return `${seconds} Secs`;
			}
		} else {
			return '';
		}
	};
	const formatAMPM = (date: Date) => {
		if (date && date.toString() !== 'Invalid Date') {
			var hours = date.getHours();
			var minutes: any = date.getMinutes();
			var ampm = hours >= 12 ? 'PM' : 'AM';
			hours = hours % 12;
			hours = hours ? hours : 12; // the hour '0' should be '12'
			minutes = minutes < 10 ? '0' + minutes : minutes;
			var strTime = hours + ':' + minutes + ' ' + ampm;
			return strTime;
		} else {
			return '';
		}
	};

	const getEventsPopoverContent = () => {
		return (
			<Box sx={{ p: 2 }} className={classes.popoverContent}>
				<div>
					<h3>{selectedAgent?.AgentName}</h3>
					<Stack direction={'column'} gap={1}>
						{selectedAgentEventDetails?.map((item: any, idx: number) => (
							<Box key={idx} display={'flex'} justifyContent="space-between" alignItems="center">
								<Box display={'flex'} gap={1}>
									{
										<Box
											sx={{
												background: getColorByAgentEvent(item.Event),
												width: '2px',
												height: '2rem',
												borderRadius: '4px'
											}}
										></Box>
									}
									<Box sx={{ display: 'flex', flexDirection: 'column' }}>
										<Stack direction={'row'} gap={5}>
											<Typography
												sx={{
													minWidth: '5rem',
													fontWeight: '500',
													fontSize: '12px',
													color: 'rgba(33, 33, 33, 0.7)'
												}}
											>
												{item.Event.toUpperCase()}
											</Typography>

											{item?.StartTime && item?.EndTime && (
												<Box
													sx={{
														display: 'flex',
														flexDirection: 'row',
														alignItems: 'center',
														gap: '5px',
														fontWeight: '400',
														fontSize: '12px',
														color: '#99A0A8'
													}}
												>
													<AccessTime sx={{ color: 'inherit', fontSize: '18px' }} />

													<Typography sx={{ color: 'inherit', fontSize: 'inherit' }}>{getTimeDifferenceInString(item?.EndTime, item?.StartTime)}</Typography>
												</Box>
											)}
										</Stack>
										<Typography
											sx={{
												fontWeight: '400',
												fontSize: '12px',
												color: '#99A0A8'
											}}
										>
											{formatAMPM(new Date(item?.StartTime))}
										</Typography>
									</Box>
								</Box>
							</Box>
						))}
					</Stack>
				</div>
			</Box>
		);
	};

	const retrieveTableData = async (filters = '0') => {
		const rows: any[] = await getAgentPerformanceDigital({ filters, type: filterType });
		const { totalRow, actualData } = getTotalRowAndActualData(rows, 'AgentName');
		const columnCount: any = {
			TotalConversation: totalRow?.TotalConversation,
			TotalCalls: totalRow?.TotalCalls,
			AnsweredCalls: totalRow?.AnsweredCalls,
			TotalConnectedPercentage: totalRow?.TotalConnectedPercentage,
			AbandonedCalls: totalRow?.AbandonedCalls,
			TotalAbandonedPercentage: totalRow?.TotalAbandonedPercentage,
			TotalSessionTime: totalRow?.TotalSessionTime,
			AVGSessionTime: totalRow?.AVGSessionTime,
			WrapupDuration: totalRow?.WrapupDuration,
			TotalPauseDuration: totalRow?.TotalPauseDuration,
			AVGResponseTime: totalRow?.AVGResponseTime
		};
		for (const row of actualData) {
			const skills = row.SkillNames?.split(',') || [];
			const tempSkills = [...skills];
			if (skills.length > 3) {
				row.skillsFormatted = { value: skills.slice(0, 3).join(','), badge: `+${skills.length - 3}`, extraValues: tempSkills.splice(3, skills.length - 1) };
			} else {
				row.skillsFormatted = skills.join(',');
			}
			row.agentEvents = (
				<span className="icon-col-sm">
					<IconButton disableRipple onClick={(event) => agentEvents(event, row)}>
						<img src={`${process.env.PUBLIC_URL}/icons/table-graph-icon.svg`} width="20" alt="Graph"></img>
					</IconButton>
				</span>
			);
		}
		const updatedRowData = mapRowDataWithThreshold(thresholdItems, actualData);
		const columns = [
			{ name: 'Agent ID', key: 'AgentUniqueID', minWidth: 100, align: 'left' },
			{ name: 'Agent', key: 'AgentName', align: 'center', minWidth: 130, type: 'AGENT_NAME_COLOR' },
			{ name: '', key: 'agentEvents', minWidth: 80, align: 'left' },
			{ name: 'Skills', key: 'skillsFormatted', minWidth: 280, align: 'left' },
			{ name: 'Total Conversations', key: 'TotalConversation', minWidth: 150, subTitle: columnCount.TotalConversation, align: 'center' },
			// { name: 'Total Interactions', key: 'TotalCalls', subTitle: columnCount.TotalCalls, align: 'right' },
			// { name: 'Answered', key: 'AnsweredCalls', subTitle: columnCount.AnsweredCalls, align: 'right' },
			{ name: 'Total Connected %', key: 'TotalConnectedPercentage', minWidth: 150, subTitle: columnCount.TotalConnectedPercentage, align: 'center' },
			// { name: 'Abandoned', key: 'AbandonedCalls', subTitle: columnCount.AbandonedCalls, align: 'right' },
			{ name: 'Total Abandoned %', key: 'TotalAbandonedPercentage', minWidth: 150, subTitle: columnCount.TotalAbandonedPercentage, align: 'center' },
			{ name: 'Total Session Time', key: 'TotalSessionTime', minWidth: 150, subTitle: columnCount.TotalSessionTime, align: 'center', type: 'MIN_SEC' },
			{ name: 'Avg. Session Time', key: 'AVGSessionTime', minWidth: 150, subTitle: columnCount.AVGSessionTime, align: 'center', type: 'MIN_SEC' },
			{ name: 'Avg. Response Time', key: 'AVGResponseTime', minWidth: 150, subTitle: columnCount.AVGResponseTime, align: 'center', type: 'MIN_SEC' },
			// { name: 'Pause Time', key: 'TotalPauseDuration', subTitle: columnCount.TotalPauseDuration, align: 'right', type: 'MIN_SEC' }
			// { name: 'Wrapup Time', key: 'WrapupDuration', subTitle: columnCount.WrapupDuration, align: 'right', type: 'MIN_SEC' }
		];
		setRowData(updatedRowData);
		setLastFetchedTime(getLastFetchedDetail());
		setColumnData(columns);
	};

	const toggleDrawer = (isOpen: boolean) => {
		setIsDrawerOpen(isOpen);
	};
	const toggleThresholdDrawer = (isOpen: boolean, data: any = null, showSkillList: any = false) => {
		setIsThresholdDrawerOpen(isOpen);
		if (data) {
			const savedThresholdList = structuredClone(data);
			setSavedThresholdList((prev: any) => ({
				showSkillList: showSkillList,
				thresholdList: savedThresholdList
			}));
			if (props?.handleThresholdChange) {
				props.handleThresholdChange?.(savedThresholdList, showSkillList, props?.widgetId);
			}
			setThresholdItems(data);
		}
	};

	const toggleRefresh = () => {
		try {
			const filterIds = (agentPerformanceFilter.length && !(responseLength === agentPerformanceFilter.length)) ? agentPerformanceFilter.map((item: any) => item.id).join(',') : '0';
			retrieveTableData(filterIds);
		} catch (e) {
			console.error("Failed to refresh filters in performance digital ")
		}
	};

	const handleDownload = (type: string) => {
		console.log(type);
	};

	const handleFilterExistingData = (filteredData: any) => {
		setAgentPerformanceFilter(filteredData);
		props?.handleFilterChange?.(filteredData, filterSection, props?.widgetId);
	};

	const handleApplyFilters = ({ selectedFilters, filterType: newFilterType, filterSection: newfilterSection }: any) => {
		setAgentPerformanceFilter(selectedFilters);
		setFilterType(newFilterType);
		setFilterSection(newfilterSection);
		props?.handleFilterChange?.(selectedFilters, newfilterSection, props?.widgetId);
		toggleDrawer(false);
	};

	React.useEffect(() => {
		const fetchWidgetConfig = async () => {
			try {
				if (checkDataAccessFilters()) {
					let widgetConfig = await getWidgetConfig('107', 'widget');
					if (widgetConfig) {
						if (typeof widgetConfig === 'string') {
							widgetConfig = JSON.parse(widgetConfig);
						}
						// Check the condition and set the appropriate filters
						const filters = widgetConfig.data && checkDataAccessFilters()
							? widgetConfig.data
							: widgetConfig.config;

						if (filters) {
							const filtersArray = filters.split(',');
							setAllowedFilters(filtersArray);
							const determinedFilterType = determineFilterType(filtersArray);
							if (determinedFilterType !== undefined) {
								setFilterType(determinedFilterType);
							}
						}
					}
				}
			} catch (error) {
				console.error('Error fetching widget config:', error);
			}
		};
		fetchWidgetConfig();
	}, []);

	React.useEffect(() => {
		return () => {
			clearInterval(interval);
		};
	}, []);

	React.useEffect(() => {
		const filterIds = (agentPerformanceFilter.length && !(responseLength === agentPerformanceFilter.length)) ? agentPerformanceFilter.map((item: any) => item.id).join(',') : '0';
		// const filterIds = agentPerformanceFilter.length ? agentPerformanceFilter.map((item: any) => item.id).join(',') : '0';
		retrieveTableData(filterIds);
		if (interval) {
			clearInterval(interval);
		}
		// interval = setInterval(() => retrieveTableData(filterIds), WIDGET_REFRESH_INTERVAL.AGENT_PERFORMANCE);
	}, [agentPerformanceFilter, thresholdItems]);

	function getRowData(row: any) {
		row = row?.map((item: any) => {
			let rowObj = {
				...item,
				skillsFormatted: item?.SkillNames ? item?.SkillNames : item?.SkillNames,
				TotalConversation: secondsToMinSec(item?.TotalConversation),
				AVGResponseTime: secondsToMinSec(item?.AVGResponseTime),
				AVGSessionTime: secondsToMinSec(item?.AVGSessionTime),
				TotalSessionTime: secondsToMinSec(item?.TotalSessionTime),
			};
			return rowObj;
		});
		return row;
	}

	return (
		<>
			<Card className="customCard">
				<div className="card-header">
					<div className="card-title">
						Agent Performance<span className="card-sub-header">(Digital)</span>
						{/* <span className="card-title-badge">Live</span> */}
						<div className="refresh-btn card-icon" onClick={() => toggleRefresh()}>
							<RefreshOutlinedIcon fontSize="small" style={{ color: '#536580' }} />
						</div>
						<span className="last-fetched-time">Last fetched at: {lastFetchedTime} </span>
					</div>
					<div className="card-actions">
						<WidgetDownload element={downloadContent} handleDownload={handleDownload} xlsx rowData={getRowData(rowData)} columns={columnData} name={'Agent Performance (Digital)'}></WidgetDownload>
						<div className="card-icon" onClick={() => toggleDrawer(true)}>
							<Badge badgeContent={agentPerformanceFilter.length} color="primary">
								<FilterAltOutlinedIcon fontSize="small" style={{ color: '#536580' }} />
							</Badge>
						</div>
						<div onClick={() => toggleThresholdDrawer(true)}>
							<WidgetGraph></WidgetGraph>
						</div>
					</div>
				</div>
				<Box sx={{ width: '100%', typography: 'body1', height: "100%" }}>
					{agentPerformanceFilter.length > 0 && (
						<Box className="customTabPanel">
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<div className="filter-outer">
										<div className="filter-container">
											<Stack direction="row" spacing={1}>
												{filterItems}
											</Stack>
										</div>
										<div className="filter-remove" onClick={clearFilter}>
											Remove
										</div>
									</div>
								</Grid>
							</Grid>
						</Box>
					)}
					< Box sx={{ width: '100%', height: agentPerformanceFilter.length > 0 ? 'calc(100% - 100px)' : 'calc(100% - 24px)', }} ref={chartContainerRef} >
						<OZTable downloadContent={downloadContent} columns={columnData} rows={rowData} noMaxHeight={true} height={Math.floor(chartDimensions.height)} />
					</Box>
				</Box>
			</Card>
			<FilterDrawer isOpen={isDrawerOpen} additionalApiParams={{ campaignType: 'Chat' }} allowedFilters={allowedFilters} toggleDrawer={toggleDrawer} applyFilters={handleApplyFilters} existingSelectedFilters={agentPerformanceFilter} setResponseLength={setresponseLength} existingFilterCategory={filterType} applyFilterOnExistingData={handleFilterExistingData} />
			{isThresholdDrawerOpen && <ThresholdDrawer
				isOpen={isThresholdDrawerOpen}
				toggleDrawer={toggleThresholdDrawer}
				title="Agent Performance"
				thresholdItems={thresholdItems}
				savedThresholdList={savedThresholdList?.thresholdList || []}
				showSkillListByDefault={savedThresholdList.showSkillList}
			/>}
			<Popover
				open={isEventsPopoverOpen}
				onClose={handleOnEventsPopoverClose}
				anchorReference="anchorPosition"
				anchorPosition={eleRefPoint}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'left'
				}}
				transformOrigin={{
					vertical: 'center',
					horizontal: 'left'
				}}
			>
				{getEventsPopoverContent()}
			</Popover>
		</>
	);
}
export default AgentPerformanceDigital;
