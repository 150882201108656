import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { ReactComponent as FilterAltOutlinedIcon } from '../../../static/icons/filter.svg';
import { Badge, Box, Card, Chip, Grid, Stack } from '@mui/material';
import * as React from 'react';
import { getFilters, getSkillSummaryDigital, getWidgetConfig } from '../../../services/api-service';
import { WIDGET_REFRESH_INTERVAL } from '../../../utils/constants';
import FilterDrawer from '../../FilterDrawer/FilterDrawer';
import OZTable from '../../OZTable/OZTable';
import WidgetDownload from '../../Widget-Download/Widget-Download';
import WidgetGraph from '../../Widget-Graph/Widget-Graph';
import '../SkillSummaryDigital/SkillSummaryDigital.css';
import ThresholdDrawer from '../../ThresholdDrawer/ThresholdDrawer';
import { checkDataAccessFilters, determineFilterType, getTotalRowAndActualData, mapRowDataWithThreshold, mapRowDataWithThresholdNew, secondsToMinSec } from '../../../utils/util';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import useChartResize from '../../../hooks/useChartResize';

const defaultFilters = [
	{ id: 1, label: 'General', color: '#EE4060' },
	{ id: 2, label: 'Canada Sales', color: '#EEBE40' },
	{ id: 3, label: 'Support', color: '#B0CFA5' },
	{ id: 4, label: 'English Sales', color: '#53B9ED' },
	{ id: 5, label: 'Premium Plus', color: '#B2A1F9' }
];

const thresholdFields = [
	{ label: 'Longest Queue Time', value: '', key: 'LongestCallQueueTime', condition: 'GREATER_THAN' },
	{ label: 'Longest Conversation Time', value: '', key: 'LongestConversationTime', condition: 'GREATER_THAN' },
	{ label: 'Total Abandoned ', value: '', key: 'TotalAbandoned', condition: 'GREATER_THAN' },
	{ label: 'Total Connected', value: '', key: 'TotalConnected', condition: 'GREATER_THAN' },
	// { label: 'ASA', value: '', key: 'AverageSpeedToAnswer', condition: 'GREATER_THAN' },
	// { label: 'AHT', value: '', key: 'AverageHandlingTime', condition: 'GREATER_THAN' }
];

let interval: any;

function SkillSummaryDigital(props: any) {
	const { useState, useRef } = React;
	const downloadContent = useRef<null | HTMLElement>(null);
	const [isDrawerOpen, setIsDrawerOpen] = useState<boolean | undefined>(false);
	const [rowData, setRowData] = useState<Array<any>>([]);
	const [columnData, setColumnData] = useState<Array<any>>([]);
	const [skillSummaryFilter, setSkillSummaryFilter] = useState<any>(props?.selectedFilters?.filters || []);
	const [filterType, setFilterType] = useState<any>(props?.selectedFilters?.filterSection?.id || (checkDataAccessFilters() ? 2 : '1'));
	const [filterSection, setFilterSection] = useState<any>(props?.selectedFilters?.filterSection || {});
	const [responseLength, setresponseLength] = useState<Number>(0);
	const [isThresholdDrawerOpen, setIsThresholdDrawerOpen] = useState<boolean | undefined>(false);
	const [thresholdItems, setThresholdItems] = useState<Array<any>>([]);
	const [callType, setCallType] = React.useState('all');
	const { chartDimensions, chartContainerRef } = useChartResize();
	const [allowedFilters, setAllowedFilters] = useState<string[]>(['Campaign', 'Skill', 'Group']);
	const [savedThresholdList, setSavedThresholdList] = useState<any>({
		thresholdList: props?.selectedThresholds?.thresholdList?.length ? props?.selectedThresholds?.thresholdList : [],
		showSkillList: props?.selectedThresholds?.showSkillList || false
	});

	const clearFilter = () => {
		setSkillSummaryFilter([]);
		props?.handleFilterChange?.([], filterSection, props?.widgetId);
	};

	const handleFilterDelete = (id: Number) => {
		const newFilter = skillSummaryFilter.filter((item: any) => item.id !== id);
		setSkillSummaryFilter(newFilter);
		props?.handleFilterChange?.(newFilter, filterSection, props?.widgetId);
	};

	const filterItems = skillSummaryFilter.map((item: any, index: number) => (
		<Chip key={index} label={item.label} color="primary" variant="outlined" onDelete={() => handleFilterDelete(item.id)} deleteIcon={<CloseOutlinedIcon style={{ fontSize: '12px' }} />} />
	));

	const retrieveTableData = async (filters = '0') => {
		const rows: any[] = await getSkillSummaryDigital({ filters, type: filterType, callType });
		const { totalRow, actualData } = getTotalRowAndActualData(rows, 'SkillName');
		const columns = [
			{ name: 'Skills', key: 'SkillName', subTitle: actualData.length },
			{ name: 'Total Conversations', key: 'TotalConversation', subTitle: totalRow?.TotalConversation ?? 0, minWidth: 180 },
			{ name: 'Total Agents', key: 'TotalAgents', subTitle: totalRow?.TotalAgents ?? 0, minWidth: 120 },
			{ name: 'Conversations Per Agents', key: 'ConversationPerAgent', subTitle: totalRow?.ConversationPerAgent ?? 0, minWidth: 180 },
			{ name: 'Longest Queue Time', key: 'LongestCallQueueTime', subTitle: totalRow?.LongestCallQueueTime ?? 0, type: 'MIN_SEC', minWidth: 200 },
			{ name: 'Longest Conversation Time', key: 'LongestConversationTime', subTitle: totalRow?.LongestConversationTime ?? 0, type: 'MIN_SEC', minWidth: 200 },
			{ name: 'Total Connected', key: 'TotalConnected', subTitle: totalRow?.TotalConnected ?? 0, minWidth: 150 },
			{ name: 'Total Connected %', key: 'TotalConnectedPercentage', subTitle: totalRow?.TotalConnectedPercentage ?? 0, minWidth: 150 },
			{ name: 'Total Abandoned ', key: 'TotalAbandoned', subTitle: totalRow?.TotalAbandoned ?? 0, minWidth: 150 },
			{ name: 'Total Abandoned %', key: 'TotalAbandonedPercentage', subTitle: totalRow?.TotalAbandonedPercentage ?? 0, minWidth: 150 },
			// { name: 'SLA %', key: 'SLAPercentage', subTitle: totalRow?.SLAPercentage ?? 0 },
			// { name: 'ASA', key: 'AverageSpeedToAnswer', subTitle: totalRow?.AverageSpeedToAnswer ?? 0, type: 'MIN_SEC' },
			// { name: 'AHT', key: 'AverageHandlingTime', subTitle: totalRow?.AverageHandlingTime ?? 0, type: 'MIN_SEC' }
		];
		// const updatedRowData = mapRowDataWithThreshold(thresholdItems, actualData);
		const updatedRowData = mapRowDataWithThresholdNew(thresholdItems, actualData);
		setRowData(updatedRowData);
		setColumnData(columns);
	};

	const toggleDrawer = (isOpen: boolean) => {
		setIsDrawerOpen(isOpen);
	};

	const handleDownload = (type: string) => {
		console.log(type);
	};

	const updateThresholdItems = async () => {
		const newThresholdItems = [];
		const response: any = await getFilters('Skill');
		newThresholdItems.push({ label: 'Organization Level', fields: thresholdFields, isOrgSkill: true });
		for (const row of response) {
			if (row.name !== 'All') {
				newThresholdItems.push({ label: row.Name, key: 'SkillName', value: row.Name, fields: thresholdFields });
			}
		}
		setThresholdItems(newThresholdItems);
	};

	React.useEffect(() => {
		const fetchWidgetConfig = async () => {
			try {
				if (checkDataAccessFilters()) {
					let widgetConfig = await getWidgetConfig('133', 'widget');
					if (widgetConfig) {
						if (typeof widgetConfig === 'string') {
							widgetConfig = JSON.parse(widgetConfig);
						}
						// Check the condition and set the appropriate filters
						const filters = widgetConfig.data && checkDataAccessFilters()
							? widgetConfig.data
							: widgetConfig.config;

						if (filters) {
							const filtersArray = filters.split(',');
							setAllowedFilters(filtersArray);
							const determinedFilterType = determineFilterType(filtersArray);
							if (determinedFilterType !== undefined) {
								setFilterType(determinedFilterType);
							}
						}
					}
				}
			} catch (error) {
				console.error('Error fetching widget config:', error);
			}
		};
		fetchWidgetConfig();
	}, []);

	// React.useEffect(() => {
	//  updateThresholdItems();
	// 	return () => {
	// 		clearInterval(interval);
	// 	};
	// }, [callType]);

	React.useEffect(() => {
		updateThresholdItems();
		return () => {
			clearInterval(interval);
		};
	}, []);

	React.useEffect(() => {

		const filterIds = (skillSummaryFilter.length && !(responseLength === skillSummaryFilter.length)) ? skillSummaryFilter.map((item: any) => item.id).join(',') : '0';
		// const filterIds = skillSummaryFilter.length ? skillSummaryFilter.map((item: any) => item.id).join(',') : '0';
		retrieveTableData(filterIds);
		if (interval) {
			clearInterval(interval);
		}
		interval = setInterval(() => retrieveTableData(filterIds), WIDGET_REFRESH_INTERVAL.SKILL_SUMMARY);


	}, [skillSummaryFilter, thresholdItems, callType]);

	const handleFilterExistingData = (filteredData: any) => {
		setSkillSummaryFilter(filteredData);
		props?.handleFilterChange?.(filteredData, filterSection, props?.widgetId);
	};

	const handleApplyFilters = ({ selectedFilters, filterType: newFilterType, filterSection: newfilterSection }: any) => {
		setSkillSummaryFilter(selectedFilters);
		setFilterType(newFilterType);
		setFilterSection(newfilterSection);
		props?.handleFilterChange?.(selectedFilters, newfilterSection, props?.widgetId);
		toggleDrawer(false);
	};

	const toggleThresholdDrawer = (isOpen: boolean, data: any = null, showSkillList: any = false) => {
		// updateThresholdItems();
		setIsThresholdDrawerOpen(isOpen);
		if (data) {
			const savedThresholdList = structuredClone(data);
			setSavedThresholdList((prev: any) => ({
				showSkillList: showSkillList,
				thresholdList: savedThresholdList
			}));
			if (props?.handleThresholdChange) {
				props.handleThresholdChange?.(savedThresholdList, showSkillList, props?.widgetId);
			}
			setThresholdItems(data);
		}
	};
	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		setCallType(newValue);
	};

	function getRowData(row: any) {
		row = row?.map((item: any) => {
			let rowObj = {
				...item,
				LongestCallQueueTime: secondsToMinSec(item?.LongestCallQueueTime),
				LongestConversationTime: secondsToMinSec(item?.LongestConversationTime),
			};
			return rowObj;
		});
		return row;
	}

	return (
		<>
			<Card className="customCard">
				<div className="card-header">
					<div className="card-title">
						Skill Summary <span className="card-sub-header">(Digital)</span>
						<span className="card-title-badge">Live</span>
					</div>
					<div className="card-actions">
						<WidgetDownload element={downloadContent} handleDownload={handleDownload} xlsx rowData={getRowData(rowData)} columns={columnData} name={'Skill Summary(Digital)'}></WidgetDownload>
						<div className="card-icon" onClick={() => toggleDrawer(true)}>
							<Badge badgeContent={skillSummaryFilter.length} color="primary">
								<FilterAltOutlinedIcon fontSize="small" style={{ color: '#536580' }} />
							</Badge>
						</div>

						<div onClick={() => toggleThresholdDrawer(true, null)}>
							<WidgetGraph></WidgetGraph>
						</div>
					</div>

				</div>
				<Box ref={downloadContent} sx={{ width: '100%', typography: 'body1' }}>
					<TabContext value={callType}>
						<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
							<TabList onChange={handleChange} className="customTabs">
								<Tab label="All" value="all" />
								<Tab label="Whatsapp" value="whatsapp" />
								<Tab label="Web Chat" value="web" />
								<Tab label="SMS" value="sms" />
								{/* <Tab label="Preview" value="preview" />
									<Tab label="Progressive" value="progressive" /> */}
							</TabList>
						</Box>
					</TabContext>
				</Box>
				<Box sx={{ width: '100%', typography: 'body1' }}>
					{skillSummaryFilter.length > 0 && (
						<Box className="customTabPanel">
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<div className="filter-outer">
										<div className="filter-container">
											<Stack direction="row" spacing={1}>
												{filterItems}
											</Stack>
										</div>
										<div className="filter-remove" onClick={clearFilter}>
											Remove
										</div>
									</div>
								</Grid>
							</Grid>
						</Box>
					)}
				</Box>
				<Box sx={{ height: skillSummaryFilter.length > 0 ? 'calc(100% - 154px)' : 'calc(100% - 84px)' }} ref={chartContainerRef}>
					<OZTable downloadContent={downloadContent} columns={columnData} rows={rowData} noMaxHeight={true} height={chartDimensions.height * 1.05} />
				</Box>
			</Card>
			{/* <FilterDrawer isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} applyFilters={handleApplyFilters} existingSelectedFilters={skillSummaryFilter} additionalApiParams={{ campaignType: 'Chat' }} setResponseLength={setresponseLength} existingFilterCategory={filterType} applyFilterOnExistingData={handleFilterExistingData} /> */}
			<FilterDrawer isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} allowedFilters={allowedFilters} applyFilters={handleApplyFilters} existingSelectedFilters={skillSummaryFilter} additionalApiParams={{ campaignType: 'Chat' }} setResponseLength={setresponseLength} existingFilterCategory={filterType} applyFilterOnExistingData={handleFilterExistingData} />
			<ThresholdDrawer
				isOpen={isThresholdDrawerOpen}
				title={'Skill Summary'}
				toggleDrawer={toggleThresholdDrawer}
				thresholdItems={thresholdItems}
				savedThresholdList={savedThresholdList?.thresholdList || []}
				showSkillListByDefault={savedThresholdList.showSkillList}
			/>
		</>
	);
}
export default SkillSummaryDigital;
