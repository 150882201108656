import React, { useState, useMemo } from 'react';
import {
    Box,
    Chip,
    Dialog,
    DialogTitle,
    DialogContent,
    List,
    ListItem,
    Typography,
    IconButton,
    LinearProgress
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { generateAuditLog, getFormattedNameBasedOnForm } from '../../../../utils/util';
import StyledTooltip from '../StylesTooltip.jsx';
import { ReactComponent as ArrowIcon } from '../../../../static/icons/arrowSide.svg';
import { getAuditLogDiff } from '../../../../services/api-service';
import { showErrorNotification } from '../NotiService';

const ignoreFields = ['Created Date', 'Modified Date', 'Id', 'Flow Id', 'Deleted Date', 'Quartz Job Id'];
const unPureObjects = ['Work Days']

export default function AuditComparison({ row, column }) {
    if (row === undefined || column === undefined) return null;
    if (row.LogAction === "SV") return <RowValue value="Saved" />;
    if (row.LogAction === "DEL") return <RowValue value="Deleted" />;
    if (row.LogAction === "UPD" && row.LogDescription) {
        if (row.LogDiff) {
            return <AuditComparisonDialog row={row} column={column} />;
        }
        return <RowValue value="No Changes Made" />;
    }
    return null;
}

const RowValue = ({ value }) => {
    return <Typography sx={{ fontSize: '12px', color: '#212121', fontWeight: 500 }}>{value}</Typography>;
};

const AuditComparisonDialog = ({ row, column }) => {
    const [open, setOpen] = useState(false);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [loading, setLoading] = useState(false);
    const module = row?.LogModule ?? '';

    const getPreviousObject = async () => {
        if (!row || !row.AttributeId || !row.LogModule || !row.AuditLogId) {
            showErrorNotification("Missing required fields");
            return;
        }
        try {
            setLoading(true);
            const response = await getAuditLogDiff({
                attributeId: row.AttributeId,
                module: row.LogModule,
                auditId: row.AuditLogId
            })
            console.log("response", response);
            if (response) {
                const after = JSON.parse(row.LogDescription);
                const before = JSON.parse(response);
                setSelectedColumns([{ data: before }, { data: after }]);
                setOpen(true);
            } else {
                showErrorNotification("No previous data found");
            }
        }
        catch (error) {
            console.log("Invalid Data", error);
            showErrorNotification("Unable to fetch data");
        } finally {
            setLoading(false);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    if (open) {
        console.log('selectedColumns', selectedColumns);
    }

    try {

        return (
            <div>
                {loading ? <LinearProgress sx={{ borderRadius: '2px', width: '80px' }} />
                    :
                    <Typography
                        sx={{
                            cursor: 'pointer',
                            color: '#008DFF',
                            fontSize: '12px',
                            fontWeight: 500,
                            width: '80px'
                        }}
                        onClick={getPreviousObject}
                    >
                        View Changes
                    </Typography>}
                <ComparisonDialog
                    open={open}
                    selectedColumns={selectedColumns}
                    onClose={handleClose}
                    header={`${row.LogModule} - ${row.AttributeName}`}
                    row={row}
                    module={module}
                />
            </div>
        );
    } catch (error) {
        console.log("Error in parsing JSON", error);
        return null;
    }
};

const ComparisonDialog = ({
    open,
    selectedColumns,
    onClose,
    header,
    module
}) => {

    const beforeTypographyStyle = useMemo(() => ({
        textDecoration: 'line-through',
        backgroundColor: 'rgba(255, 0, 0, 0.1)',
        padding: '2px 4px',
        fontSize: '11px',
        color: '#212121',
        borderRadius: '6px',
        flexWrap: 'wrap',
        wordBreak: 'break-all'
    }), []);

    const afterTypographyStyle = useMemo(() => ({
        backgroundColor: 'rgba(0, 0, 255, 0.1)',
        padding: '2px 4px',
        fontSize: '11px',
        color: '#212121',
        borderRadius: '6px',
        flexWrap: 'wrap',
        wordBreak: 'break-all'
    }), []);

    if (selectedColumns.length === 0 ||
        selectedColumns[0]?.data === undefined ||
        selectedColumns[1]?.data === undefined) {
        return null;
    }

    const diff = generateAuditLog(selectedColumns[0]?.data, selectedColumns[1]?.data);
    const diffKeys = Object.keys(diff).filter(key => key !== 'modifiedDate' && key !== 'createdDate');
    console.log("diff", {
        diff,
        diffKeys
    })

    const renderUnPureObject = (key) => {
        key = getFormattedNameBasedOnForm(module, key);
        return <ListItem sx={{ display: 'flex', py: 0.4, alignItems: 'center' }}>
            <Typography
                sx={{
                    textTransform: 'capitalize',
                    width: '100px',
                    flexWrap: 'wrap',
                    fontSize: '12px',
                }}
            >
                {key}:
            </Typography>
            <Typography
                sx={{
                    ...beforeTypographyStyle,
                    textDecoration: 'none',
                }}
            >
                Modified
            </Typography>
        </ListItem>
    }


    const renderChips = (items, color, maxDisplay = 3) => {
        if (items.length === 0) return null;

        const displayItems = items.slice(0, maxDisplay);
        const remainingItems = items.slice(maxDisplay);

        return (
            <>
                {displayItems.map((item, index) => (
                    <StyledTooltip
                        key={index}
                        placement="top"
                        title={item}
                    >
                        <Chip
                            label={item}
                            size="small"
                            variant="outlined"
                            sx={{
                                mr: 0.5,
                                p: 0,
                                background: color === 'success' ? 'rgba(0, 0, 255, 0.1)' : color === 'error' ? 'rgba(255, 0, 0, 0.1)' : '#e3f2fd',
                                color: '#212121',
                                border: 'none',
                                fontSize: '11px',
                                maxWidth: '140px',
                                height: '20px',
                            }}
                        />
                    </StyledTooltip>
                ))}
                {remainingItems.length > 0 && (
                    <StyledTooltip
                        placement="top"
                        title={
                            <Box sx={{
                                maxHeight: '300px',
                                maxWidth: "600px",
                                overflowY: 'auto',
                                py: 0.5,
                                position: "relative",
                                minHeight: '100%',
                                display: 'grid',
                                gridTemplateColumns: 'repeat(1, 1fr)',
                                gridGap: '4px',
                            }}>
                                {renderChips(remainingItems, color, remainingItems.length)}
                            </Box>
                        }
                    >
                        <Chip
                            label={`+${remainingItems.length}`}
                            size="small"
                            variant="outlined"
                            sx={{
                                background: color === 'success' ? 'rgba(0, 0, 255, 0.1)' : color === 'error' ? 'rgba(255, 0, 0, 0.1)' : '#e3f2fd',
                                color: '#212121',
                                border: 'none',
                                fontSize: '11px',
                                height: '20px',
                            }}
                        />
                    </StyledTooltip>
                )}
            </>
        );
    };

    const renderArrayChanges = (key, changes) => {
        key = getFormattedNameBasedOnForm(module, key);
        const hasChanges = changes.added.length > 0 || changes.removed.length > 0 || changes.modified.length > 0;
        if (!hasChanges) return null;
        if (unPureObjects.includes(key)) {
            return renderUnPureObject(key);
        }

        return (
            <ListItem sx={{ display: 'block', py: 0.5 }}>
                {changes.added.length > 0 && (
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <Typography
                            sx={{
                                textTransform: 'capitalize',
                                width: '100px',
                                flexWrap: 'wrap',
                                fontSize: '12px'
                            }}
                        >
                            {key} Added:
                        </Typography>
                        {renderChips(changes.added, 'success')}
                    </div>
                )}
                {changes.removed.length > 0 && (
                    <div style={{ margin: changes.added.length > 0 ? '8px 0px 0px 0px' : '0', display: 'flex', flexWrap: 'wrap' }}>
                        <Typography
                            sx={{
                                textTransform: 'capitalize',
                                width: '100px',
                                flexWrap: 'wrap',
                                fontSize: '12px'
                            }}
                        >
                            {key} Removed:
                        </Typography>
                        {renderChips(changes.removed, 'error')}
                    </div>
                )}
            </ListItem>
        );
    };

    const renderValueChange = (key, change) => {
        console.log("key", key, change);
        key = getFormattedNameBasedOnForm(module, key);
        if (ignoreFields.includes(key)) return null;

        if (typeof change === 'object' && !change.hasOwnProperty('before') && !change.hasOwnProperty('after')) {
            return Object.keys(change).map((subKey) => renderValueChange(subKey, change[subKey]));
        }

        if (change.before === change.after) return null;

        let actionType = 'to';
        if (change.before === undefined || change.before === null) {
            actionType = 'added';
        }
        if (change.after === undefined || change.after === null) {
            actionType = 'removed';
        }

        let isBooleanValue = typeof change.before === 'boolean' || typeof change.after === 'boolean';

        if (isBooleanValue) {
            if (typeof change.before === 'boolean' && typeof change.after === 'boolean') {
                change.before = change.before ? 'Enabled' : 'Disabled';
                change.after = change.after ? 'Enabled' : 'Disabled';
            } else if (change.hasOwnProperty('before') && !change.hasOwnProperty('after')) {
                actionType = 'removed';
                change.before = change.before ? 'Enabled' : 'Disabled';
            } else if (!change.hasOwnProperty('before') && change.hasOwnProperty('after')) {
                actionType = 'added';
                change.after = change.after ? 'Enabled' : 'Disabled';
            }
            else {
                return null;
            }
        }

        if (Array.isArray(change.before) || Array.isArray(change.after)) {
            return renderArrayChanges(key, {
                added: change.after || [],
                removed: change.before || [],
                modified: []
            });
        }

        if (!isBooleanValue && !change.after && change.before && change.before !== '') {
            actionType = 'removed';
            change.before = 'Removed';
        }

        if (!isBooleanValue && !change.before && (change?.after || change?.after === '')) {
            if (change.after === '') return null;
            actionType = 'added';
            change.after = 'Added';
        }

        if (unPureObjects.includes(key)) {
            return renderUnPureObject(key);
        }

        console.log("actionType", actionType, change);
        return (
            <ListItem sx={{ display: 'flex', py: 0.4, alignItems: 'center' }}>
                <Typography
                    sx={{
                        textTransform: 'capitalize',
                        width: '100px',
                        flexWrap: 'wrap',
                        fontSize: '12px',
                    }}
                >
                    {key}:
                </Typography>
                {actionType === 'to' && (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography sx={{ mr: 0.5, ...beforeTypographyStyle }}>
                            {change.before}
                        </Typography>
                        <ArrowIcon style={{ width: '20px', height: '20px' }} />
                        <Typography sx={{ ml: 0.5, ...afterTypographyStyle }}>
                            {change.after}
                        </Typography>
                    </Box>
                )}
                {actionType === 'added' && (
                    <Typography sx={afterTypographyStyle}>
                        {change.after}
                    </Typography>
                )}
                {actionType === 'removed' && (
                    <Typography sx={{ ...beforeTypographyStyle, textDecoration: (change.before === 'Removed' || change.before === 'Disabled' || !change.before) ? 'none' : 'line-through' }}>
                        {change.before || 'Removed'}
                    </Typography>
                )}
            </ListItem>
        );
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            PaperProps={{
                sx: {
                    borderRadius: "8px",
                    maxWidth: '600px',
                    minWidth: '330px'
                }
            }}
        >
            <DialogTitle sx={{ p: 1, px: 2, pb: 0.4 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography flexGrow={1} variant="body1">{header}</Typography>
                    <IconButton size="small" onClick={onClose}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent sx={{ px: 0, py: 0 }}>
                {diffKeys.length === 0 ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30px', mb: 2 }}>
                        <Typography fontSize={'14px'} color="text.secondary">No differences found</Typography>
                    </Box>
                ) : (
                    <List dense sx={{ pt: 0 }}>
                        {Object.entries(diff).map(([key, value]) => (
                            value.hasOwnProperty('added') ?
                                renderArrayChanges(key, value) :
                                renderValueChange(key, value)
                        ))}
                    </List>
                )}
            </DialogContent>
        </Dialog>
    );
};